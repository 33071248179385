import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { GlobalStyles } from "./global-styles"
import { Wrap } from "./wrap"
import { colors, layers, spacers, typography } from "../theme"
import { sm } from "../utils"

const Navbar = styled.div`
  padding: ${spacers.md} 0;
  box-shadow: 0 3px 8px 0 ${colors.boxShadow};
  z-index: ${layers.nav};
`

const NavbarInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const NavbarItem = styled.div`
  :not(:last-child) {
    padding-right: ${spacers.md};
  }
`

const BrandImageContainer = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;

  ${sm(`
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
  `)}
`

const SalesEmailLink = styled.a`
  display: none;
  font-size: ${typography.fontSizes.sm};
  font-weight: 600;
  color: ${colors.brandBlue};

  ${sm(`display: block;`)}
`

export const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyles />
      <Navbar>
        <Wrap>
          <NavbarInner>
            <NavbarItem>
              <BrandImageContainer>
                <Img
                  fluid={data.image.childImageSharp.fluid}
                  alt="enzyme software logo"
                />
              </BrandImageContainer>
            </NavbarItem>
            <NavbarItem>
              <SalesEmailLink
                href="mailto:sales@enzymesoftware.co.uk"
                aria-label="sales email"
              >
                sales@enzymesoftware.co.uk
              </SalesEmailLink>
            </NavbarItem>
          </NavbarInner>
        </Wrap>
      </Navbar>
      {children}
    </>
  )
}
